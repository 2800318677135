import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputpopupComponent } from './inputpopup.component';
import { FormsModule } from '@angular/forms';
import { UnitComponentModule } from '../unitinput/unitinput.module';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UnitComponentModule,
        TranslateModule,
        IonicModule,
    ],
    declarations: [
        InputpopupComponent
    ]

})

export class InputpopupComponentModule { }
