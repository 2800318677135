import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TimePipe} from "./timepipe";
import {UnitPipe} from "./unitpipe";
import {ZifferPipe} from "./zifferpipe";



@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [
    TimePipe,
    UnitPipe,
    ZifferPipe,
  ],
  exports: [
    TimePipe,
    UnitPipe,
    ZifferPipe,
  ]
})
export class PipesModule { }
