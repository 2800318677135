import { Component, OnInit, Input } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { UnitClass } from 'src/app/classes/unit.class';
import { ConvertService } from 'src/app/services/convert.service';

@Component({
  selector: 'app-inputpopup',
  templateUrl: './inputpopup.component.html',
  styleUrls: ['./inputpopup.component.scss'],
})
export class InputpopupComponent implements OnInit {

  inputvalue: UnitClass;

  @Input() get inpvalue() {
    return this.inputvalue;
  }
  set inpvalue(v) {
    this.inputvalue = v;
  }

  @Input() inpunits;
  @Input() inplabel;

  inpType = '';


  constructor(private popoverCtrl: PopoverController, public navParams: NavParams, public cvt: ConvertService) { }

  ngOnInit() {
    // console.log(this.popoverCtrl);
    this.inpvalue = this.navParams.get('inputPopup');
    this.inpunits = this.navParams.get('inputUnits');
    this.inplabel = this.navParams.get('inputDescription');
    this.inpType = this.navParams.get('inputType');
  }

  selectValue(i) {
    let o ;
    if ( this.inpType !== 'string' ) {
       o = { ...i, inputType: this.inpType };
    } else {
      o = { value: i, inputType: this.inpType };
    }
    this.popoverCtrl.dismiss(o);
  }

  cancel() {
    this.popoverCtrl.dismiss(null);
  }
}
