import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ConvertService} from 'src/app/services/convert.service';
import {TranslateService} from '@ngx-translate/core';
import {IUnitClass} from 'src/app/classes/unit.class';
import {isNumber} from "@turf/turf";
import {SettingsService} from "../../services/settings.service";


@Component({
    selector: 'app-unitinput',
    templateUrl: './unitinput.component.html',
    styleUrls: ['./unitinput.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UnitinputComponent),
            multi: true
        }
    ]
})
export class UnitinputComponent implements OnInit, ControlValueAccessor {

    @Input() label: string;
    @Input() store: string;
    @Input() readonly: false;
    @Input() disableChange: false;
    @Input() roundto: number = 10;

    @Input() inputclass: '';

    @Input() units: any;

    @Output() onFocus = new EventEmitter();
    @Output() onBlur = new EventEmitter();
    @Output() onKeyUp = new EventEmitter();

    @ViewChild('inp', {static: false}) inp: any;
    public editmode = false;
    // tslint:disable-next-line:variable-name
    private _ngModel: IUnitClass;
    public displayValue:number;

    constructor(public cvt: ConvertService,
                private settings:SettingsService,
                public translate: TranslateService,

               ) {
    }

    public get value() {
        return this._ngModel;
    }

    public set value(v: IUnitClass) {
        this._ngModel = v;

        try {
           this.displayValue = this.roundValue(v.value)
            this.units = this.cvt.UnitList[this.value.unittype].units;
        } catch (e) {
          this.displayValue = 0
        }

        if (!this.disableChange) {
            this.onChange(this._ngModel);
            this.onTouched();
        }
    }

    ngOnInit() {
        try {
            this.units = this.cvt.UnitList[this._ngModel?.unittype].units;
        } catch (e) {
        }
        try {
          this.displayValue = this.roundValue(this._ngModel.value);
        } catch(e) {
          this.displayValue = 0
        }
      }

    setDisabledState ?(isDisabled: boolean): void {}
    onChange: any = (d) => {}

    onFocused: any = (d) => {
        if (this.onFocus) {
            this.onFocus.emit(d);
            this.editmode = !this.readonly;
        }
    }

    onBlured: any = (d) => {
        if (this.onBlur) {
            this.onBlur.emit(d);
            this.editmode = false;
        }
    }

    onTouched: any = (d) => {}

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    async writeValue(obj: any) {
      try{
          this.displayValue = this.roundValue(obj?.value)
          this._ngModel = obj;
        } catch(e) {

      }
      try {
        this.units = this.cvt.UnitList[this.value.unittype].units;
        await this.settings.setValue(this.store, obj);
      } catch (e) {
      }
    }

    async onValueChanged() {
        this._ngModel.value = this.displayValue;
        this.value =  this._ngModel;
        await this.settings.setValue(this.store, this._ngModel);
        try {
            this.units = this.cvt.UnitList[this.value.unittype].units;
        } catch (e) {
        }
    }

    async onUnitChanged(newUnit) {
        if (this._ngModel.unit !== newUnit) {
          const v = this.cvt.gVwU(this._ngModel, newUnit);
            this._ngModel.value = this.roundValue( v);
            this._ngModel.unit = newUnit;
            this.value = this._ngModel;
            await this.settings.setValue(this.store, this.value);
        }
    }

    roundValue ( v, roundit = 0) {
      try {
      if (roundit===0) {  
        switch (true) {
          case v < 0.001:
            v = Number ( parseFloat(v.toPrecision(5))).toFixed(5);
            break;
          case v < 0.01:
            v = Number ( parseFloat(v.toPrecision(4))).toFixed(4);
            break;
          case v < 0.1:
            v =Number(  parseFloat(v.toPrecision(3))).toFixed(3);
            break;
          default:
            v = Number(v)
        }
      }
      } catch(e){}
      return v;
    }

    setEditmode() {
        this.editmode = !this.readonly;
    }

}
