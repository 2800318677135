// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.marked {
  border: 1px solid #e8fac5;
  background-color: rgb(255, 255, 183);
}

.marked_as_calculated {
  border: 1px solid #aaaaaa;
  background-color: #dcdcdc;
}

.unitlabel {
  font-weight: bolder;
  background-color: transparent;
}

.clickEnableCls {
  pointer-events: visible !important;
}

ion-select {
  -webkit-padding-end: 0;
          padding-inline-end: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/unitinput/unitinput.component.scss"],"names":[],"mappings":"AACA;EACI,yBAAA;EACA,oCAAA;AAAJ;;AAGA;EACI,yBAAA;EACA,yBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,6BAAA;AAAJ;;AAGA;EACI,kCAAA;AAAJ;;AAGA;EACI,sBAAA;UAAA,qBAAA;AAAJ","sourcesContent":["\n.marked {\n    border:1px solid #e8fac5 ;\n    background-color:rgb(255, 255, 183) ;    \n}\n\n.marked_as_calculated {\n    border:1px solid #aaaaaa ;\n    background-color: #dcdcdc ;    \n}\n\n.unitlabel {\n    font-weight: bolder;\n    background-color: transparent;\n}\n\n.clickEnableCls{\n    pointer-events: visible !important;\n }\n\nion-select {\n    padding-inline-end: 0;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
