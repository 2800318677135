import { Pipe, PipeTransform } from '@angular/core';
/*
  formatiert Ziffer mit führenden Nullen Bsp.: Ziffer: "000"
*/
@Pipe({name: 'Ziffer'})
export class ZifferPipe implements PipeTransform {
    constructor() {}

    transform(value: any, param: string): string {
        if (param?.length > 1) {
            return (param + value).slice(-1 * param.length);
        }
        else {
            return value.toString();
        }
    }
}
