import { Pipe, PipeTransform } from '@angular/core';
import {ConvertService} from '../services/convert.service';
/*

*/
@Pipe({name: 'Unit'})
export class UnitPipe implements PipeTransform {
    constructor(private cvt: ConvertService) {}

    transform(value: any, param: string): number {
        const p = param.split('-');
        const v = parseFloat (value);
        if (p.length >= 3) {
            const newVal =  this.cvt.valueWithUnit(v, p[0], p[1], p[2]);
            if (p.length === 4)  {
                return this.cvt.roundTo(newVal, parseInt(p[3], 10));
            } else {
                return newVal;
            }
        }
        else {
            return value;
        }
    }
}
