import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment-timezone";

@Pipe({name: 'Time'})
export class TimePipe implements PipeTransform {
  constructor () {}

  transform(value: any, param: string): string {
    //console.log(value, param);

    if (param.length>0) {
      return moment.utc(value*1000).format(param);
    } else {
      return moment.utc(value*1000).format('HH:mm:ss');
    }
  }
}
