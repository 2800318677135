export const environment = {
  production: false,
  version: '3.06',
  weatherApiKey: '65ecc7005e61eb8b242aa7b1dfdb05d4',
  metarApiUrl: 'https://goaround.org/api/getmetar.php',
  xweatherApiUrl: 'https://api.openweathermap.org/data/2.5/onecall',
  weatherAPILocation: 'https://api.openweathermap.org/geo/1.0/direct',
  weatherApiIcon: 'https://openweathermap.org/img/wn/',
  weatherApiUrl: 'https://goaround.org/api/getWeather.php',
  searchAirports: 'https://goaround.org/api/airports.php',
  getAirport: 'https://goaround.org/api/getairport.php',
  getRunways: 'https://goaround.org/api/getrunway.php',
  gpsOptions : { maximumAge: 3000, timeout: 5000, enableHighAccuracy: true }
};
