import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'calc',
    loadChildren: () => import('./calc/calc.module').then(m => m.CalcPageModule)
  },
  {
    path: 'wind',
    loadChildren: () => import('./wind/wind.module').then( m => m.WindPageModule)
  },
  {
    path: 'glidepath',
    loadChildren: () => import('./glidepath/glidepath.module').then(m => m.GlidepathPageModule)
  },
  {
    path: 'startstrecke',
    loadChildren: () => import('./startstrecke/startstrecke.module').then(m => m.StartstreckePageModule)
  },
  {
    path: 'burndown',
    loadChildren: () => import('./burndown/burndown.module').then(m => m.BurndownPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'metar',
    loadChildren: () => import('./metar/metar.module').then( m => m.MetarPageModule)
  },
  {
    path: 'windtriangle',
    loadChildren: () => import('./windtriangle/windtriangle.module').then( m => m.WindtrianglePageModule)
  },
  {
    path: 'weather',
    loadChildren: () => import('./weather/weather.module').then( m => m.WeatherPageModule)
  },
  {
    path: 'aircraft',
    loadChildren: () => import('./aircraft/aircraft.module').then(m => m.AircraftPageModule)
  },
  {
    path: 'aircraftdata',
    loadChildren: () => import('./aircraftdata/aircraftdata.module').then(m => m.AircraftdataPageModule)
  },
  {
    path: 'vorsim',
    loadChildren: () => import('./vor/vor.module').then(m => m.VorPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'convert',
    loadChildren: () => import('./convert/convert.module').then( m => m.ConvertPageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpModule)
  },
  {
    path: 'empty',
    loadChildren: () => import('./empty/empty.module').then( m => m.EmptyPageModule)
  },
  { path: '**',
    loadChildren: () => import('./empty/empty.module').then( m => m.EmptyPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
