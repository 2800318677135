import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {MenuController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {SettingsService} from './services/settings.service';
import {Router} from '@angular/router';
import {Subscription} from "rxjs";


@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

  private subscriptions: Subscription;

  public appPages = [
    { title: 'HOME.TITLE', url: '/home', icon: 'home' },
    { title: 'WIND.TITLE', url: '/wind', src:"/assets/icon/wind-sock.svg"},
    { title: 'WIND.WINDTRIANGLE', url: '/windtriangle', src:"/assets/icon/windtriangle.svg" },
    { title: 'METAR.TITLE', url: '/metar', icon: 'partly-sunny' },
    { title: 'WEATHER.TITLE', url: '/weather', icon: 'cloud' },
    { title: 'GLIDEPATH.TITLE', url: '/glidepath', src:"../assets/images/plane_arrival.svg" },
    { title: 'CONVERT.TITLE', url: '/convert', icon: 'git-compare' },
    { title: 'CALCULATIONS.TITLE', url: '/calc', icon: 'calculator' },
    { title: 'TIMECALC.TITLE', url: '/calc/timecalc', src:"../assets/images/calculadora.svg" },
    { title: 'AIRCRAFT.Weight & Balance', url: '/aircraft', src:"../assets/images/cg.svg" },
    { title: 'STARTLENGTH.TITLE', url: '/startstrecke', src:"../assets/images/departures.svg"},
    { title: 'FUEL.TITLE', url: '/burndown', src:"../assets/images/gasoline-pump.svg" },
    { title: 'VOR NavAid', url: '/vorsim', src:"../assets/images/vor.svg" },
  ];

  public appPages2 = [
    { title: 'ABOUT.TITLE', url: '/about', icon: 'help' },
    { title: 'Info', url: '/info', icon: 'bulb' },

    { title: 'AIRCRAFT.TITLE', url: '/aircraftdata', src:"../assets/images/aeroplane.svg" },
    { title: 'SETTINGS.TITLE', url: '/settings', icon: 'build' },
    { title: 'HELP.TITLE', url: '/help/main', icon: 'help-circle' },
  ];

  constructor(
    private router: Router,
    private settingsService: SettingsService,
    private menuController: MenuController,
    private translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.translate.addLangs(['en', 'de']);
    this.translate.setDefaultLang('en');
    this.initializeApp();
  }

  initializeApp() {
    let w = this.settingsService.getStartWindow();
    if (w) {
      this.router.navigate([w]).then();
    } else {
    }
}

  async closeMenu() {
    await this.menuController.close('mainContent');
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe()
  }
}
