import {Injectable} from '@angular/core';
import {UnitClass} from '../classes/unit.class';
import {isNumber} from "@turf/turf";

interface IStdModel {
  href: number;
  ai: number;
  Tref: number;
  rref: number;
  prefPA: number;
}

export enum Units {
  SPEED = 'S',
  DISTANCE = 'D',
  VOLUME = 'V',
  BURNDOWN = 'B',
  PRESSURE = 'P',
  WEIGHT = 'W',
  MOMENT = 'M',
  TEMPERATURE = 'T',
  BURNDOWNHOUR = 'BH',
  BURNDOWNDISTANCE = 'BD',
  DEGREE = 'DG'
}


@Injectable({
  providedIn: 'root'
})
export class ConvertService {

  public SPEEDCONV = {kmh: 1.0, mps: 0.277778, ftpmin: 54.681, kt: 0.539957, mph: 0.621371};
  public DISTANCECONV = {m: 1, ft: 3.28084, km: 0.001, mi: 0.000621371, nm: 0.000539957, in: 39.3701, mm: 1000};
  public VOLUMECONV = {l: 1, bgal: 0.219969, usgal: 0.264172, uspint: 2.11338, usquart: 1.0566900005191, qz: 61.02374};
  public BURNDOWNCONVH = {lph: 1, bgalh: 0.219969, usgalh: 0.264172};
  public BURNDOWNCONVD = {
    lpkm: 1,
    bgalkm: 0.219969,
    usgalkm: 0.264172,
    bgalmi: 0.219969 * 0.000621371,
    usgalmi: 0.264172 * 0.000621371,
    lpmi: 0.000621371,
    bgalnm: 0.219969 * 0.000539957,
    usgalnm: 0.264172 * 0.000539957,
    lpnm: 0.000539957
  };
  public PRESSURECONV = {
    mbar: 1,
    bar: 0.001,
    psi: 0.0145037738,
    hpa: 1,
    inhg: 0.02953,
    pa: 100,
    atm: 0.0009869233,
    mmhg: 0.0750063755,
    torr: 0.7500616827
  };
  public WEIGHTCONV = {kg: 1, oz: 32.274, st: 0.157, lb: 2.2046};
  public MOMENTCONV = {kgm: 1};
  public DEGREECONV = {degree: 1, rad: Math.PI / 180};
  public TEMPERATURECONV = {
    C: (v, toNorm) => v,
    F: (v, toNorm) => toNorm ? (v - 32) * 5 / 9 : v * 9 / 5 + 32,
    K: (v, toNorm) => toNorm ? v - 273.15 : v + 273.15
  };

  public UnitList = {
    S: {title: 'CONVERT.SPEED', units: this.SPEEDCONV, default: 'kmh'},
    D: {title: 'CONVERT.DISTANCE', units: this.DISTANCECONV, default: 'm'},
    V: {title: 'CONVERT.VOLUME', units: this.VOLUMECONV, default: 'l'},
    BH: {title: 'CONVERT.BURNDOWN', units: this.BURNDOWNCONVH, default: 'lph'},
    BD: {title: 'CONVERT.BURNDOWN', units: this.BURNDOWNCONVD, default: 'lpkm'},
    P: {title: 'CONVERT.PRESSURE', units: this.PRESSURECONV, default: 'mbar'},
    W: {title: 'CONVERT.WEIGHT', units: this.WEIGHTCONV, default: 'kg'},
    M: {title: 'CONVERT.MOMENT', units: this.MOMENTCONV, default: 'kgm'},
    DG: {title: 'DEGREE', units: this.DEGREECONV, default: 'degree'},
    DGO: {title: 'DEGREE', units: {degree: 1}, default: 'degree'},
    T: {title: 'CONVERT.TEMPERATURE', units: this.TEMPERATURECONV, default: 'C'}
  };

  // https://en.wikipedia.org/wiki/Barometric_formula
  // href [m]	αi [K/m]	Tref [K]	ρref [g/m3]	pref [Pa]
  public StdModel: Array<IStdModel> = [
    {href: 71000, ai: -0.002, Tref: 214.65, rref: 0.064211, prefPA: 3.95642},
    {href: 51000, ai: -0.0028, Tref: 270.65, rref: 0.861605, prefPA: 66.94},
    {href: 47000, ai: 0, Tref: 270.65, rref: 1.42753, prefPA: 110.906},
    {href: 32000, ai: 0.0028, Tref: 228.65, rref: 13.225, prefPA: 868.019},
    {href: 20000, ai: 0.001, Tref: 216.65, rref: 88.0348, prefPA: 5474.89},
    {href: 11000, ai: 0, Tref: 216.65, rref: 363.918, prefPA: 22632.1},
    {href: 0, ai: -0.0065, Tref: 288.15, rref: 1225.00, prefPA: 101325}
  ];

  public g = 9.80665;  // m/s
  public R = 8.31446;  // universelle Gaskonstante
  public RS = 287.058;  // Gaskonstante in trockerner Luft
  public kappa = 1.4;
  public M = 28.9644;

  public T0 = 288.15;     // Temperatur auf Meereshöhe (15°  STD)
  public p0 = 101325;     // Druck auf Meereshöhe (STD)
  public rho0 = 1.225;   // Luftdichte auf Meereshöhe
  public a0 = 340.294;


  constructor() {
    //this.saveDefaults();
    //this.getDefaults();
  }

  getDefaults() {
    /*
    this.settings.get('DEFUNITS').then ( defunits => {
         Object.keys(defunits).forEach( unit => {
             this.UnitList[unit].default = defunits[unit];
         });
     });
     */
    console.log(this.UnitList);
  }

  saveDefaults() {
    const units = [];
    Object.keys(this.UnitList).forEach(unit => {
      units[unit] = this.UnitList[unit].default;
    });
    //  this.settings.setValue('DEFUNITS', units);
  }

  setDefaultUnit(unitType: Units, unit: string) {
    const currentUnit = this.UnitList[unitType];
    if (currentUnit?.units[unit]) {
      currentUnit.default = unit;
    }
    this.saveDefaults();
  }

  /**
   *
   * @param h ermittelt die Berechnungswerte für die Standardathmosphäre aus der Höhe.
   */
  public getStdfromH(h): IStdModel {
    this.StdModel.forEach(d => {
      if (h > d.href) {
        return d;
      }
    });
    return this.StdModel[6];
  }


  /**
   *
   * @param h Berechnet die Temperatur in einer bestimmten Höhe nach Std.
   */
  public TemperatureinH(h) {
    const d = this.getStdfromH(h);
    return d.Tref + d.ai * (h - d.href);
  }

  public PressureinH(h) {
    const d = this.getStdfromH(h);
    if (d.ai === 0) {
      const hs = this.RS * d.Tref / this.g;
      return d.prefPA * Math.exp(-(h - d.href) / hs);
    } else {
      const beta = this.g / this.RS / d.ai;
      return d.prefPA * Math.pow(1 + d.ai * (h - d.href) / d.Tref, -beta);

    }
  }


  /**
   * Rechnet einen Wert in eine andere Einheit um
   * @param v // Wert
   * @param currentUnit // aktuelle Einheit des Wertes
   * @param destUnit    // gewüschte Einheit des Wertes
   * @param unitlist    // zugehörige Umrechnungsliste
   */
  public calcValueWithUnit(v, currentUnit: string, destUnit: string, unitlist: Units):number {
    if (destUnit === currentUnit) {
      return parseFloat(v);
    }

    const ul = this.UnitList;

    if (this.isFunction(unitlist[currentUnit])) {
      const n = unitlist[currentUnit](v, true);
      const calc = unitlist[destUnit](n, false);
      return calc;
    } else {
      return parseFloat(v) / parseFloat(unitlist[currentUnit]) * parseFloat(unitlist[destUnit]);
    }
  }

  public gVwU(v: UnitClass, u: string) {
    try{
    const unitlist = this.UnitList[v.unittype].units;
    return this.calcValueWithUnit(v.value, v.unit, u, unitlist);
    } catch(e) {
   
    }
  }

  public sVwU(src: UnitClass, v: number, u: string, roundto = -1) {
    try {
    const unitlist = this.UnitList[src.unittype].units;
    let vneu = 0;
    if (this.isFunction(unitlist[u])) {
      vneu = unitlist[u](v, true);
      vneu = unitlist[src.unit](vneu, false);
    } else {
      vneu = v / unitlist[u] * unitlist[src.unit];
    }

     vneu = this.roundTo(vneu, roundto);

    return new UnitClass(src.unit, vneu, src.rangefrom, src.rangeto, src.unittype);
  } catch(e) {
    
  }
  }


  public cU(src: UnitClass, newU: string, roundto = -1) {
    const unitlist = this.UnitList[src.unittype].units;
    let vneu = 0;
    if (this.isFunction(unitlist[newU])) {
      vneu = unitlist[src.unit](src.value, true);  // aktuellen Wert in normwert umrechen
      vneu = unitlist[newU](vneu, false);  // neuen Wert in neue Einheit konvertieren
    } else {
      vneu = src.value / unitlist[src.unit] * unitlist[newU];
    }
    if (roundto >= 0) {
      vneu = this.roundTo(vneu, roundto);
    }
    return new UnitClass(newU, vneu, src.rangefrom, src.rangeto, src.unittype);
  }

  public cvtU(v: UnitClass, u) {
    const vu = v.unit;
  }

  valueWithUnit(value, currentUnit, destUnit, units: string) {
    const ul = this.UnitList[units].units;
    return this.roundTo(this.calcValueWithUnit(value, currentUnit, destUnit, ul), 2);
  }

  deg2rad(v) {
    return v * Math.PI / 180.0;
  }

  rad2deg(v) {
    return v * 180 / Math.PI;
  }

  roundTo(v, num=0) {

    if (num<0) {
      return v
    }
    if (num===0) {
      switch (true) {
        case v < 0.001:
          v = parseFloat(v.toPrecision(5));
          break;
        case v < 0.01:
          v = parseFloat(v.toPrecision(4));
          break;
        case v < 0.1:
          v = parseFloat(v.toPrecision(3));
          break;
        case isNumber(v):
          v = parseFloat(v.toPrecision(2));
          break;
        default:
      }
      return v
    } else {
      return Math.round(v * Math.pow(10, num)) / Math.pow(10, num);
    }
  }

  round(v) {
    return Math.round(v);
  }

  normAngleInput(v: UnitClass) {
    let v0 = this.gVwU(v, 'degree');
    v0 = this.normAngleDeg(v0);
    v = this.sVwU(v, v0, 'degree');
    return v;
  }

  normAngleRad(v) {
    v = v % (2 * Math.PI);
    if (v < 0) {
      v = v + (2 * Math.PI);
    }
    return v;
  }

  normAngleDeg(v) {
    v = v % 360;
    if (v < 0) {
      v = v + 360;
    }
    return v;
  }

  deltaArcDeg(x, y) {
    let d = y - x;
    if (d < -180) {
      d = d + 360;
    } else if (d > 180) {
      d = d - 360;
    }
    return d;
  }

  CtoF(v) {
    return v * 9 / 5 + 32.0;
  }

  FtoC(v) {
    return (v - 32) * 5 / 9;
  }

  CtoK(v) {
    return v + 273.15;
  }

  KtoC(v) {
    return v - 273.15;
  }


  luvWinkel(vw, ve, wd, TC) {
    if (ve === 0) {
      return 0;
    }
    return Math.asin(vw / ve * Math.sin(wd - TC));
  }


  calcHeadWind(TH, WD, Vw) {
    const delta = Math.abs((TH - 180 - WD) % 360);
    return Math.abs(Math.cos(delta * Math.PI / 180.0) * Vw);
  }


  calcwindSpeed_fromCourse(TC, TH, TAS, GS) {
    return Math.sqrt(Math.pow(TAS - GS, 2) + 4 * TAS * GS * Math.pow(Math.sin((TH - TC) / 2), 2));
  }


  calcWindDirection_fromCourse(TC, TH, TAS, GS) {
    return this.normAngleRad(TC + Math.atan2(TAS * Math.sin(TH - TC), TAS * Math.cos(TH - TC) - GS));
  }

  calcGroundSpeed(vw, ve, TC, WD, L) {
    return Math.sqrt(vw * vw + ve * ve - 2 * vw * ve * Math.cos(WD - TC - L));
  }

  windDirection(tc, th, TAS, GS) {
    const dc = th - tc;
    let wd = tc + Math.atan2(TAS * Math.sin(dc), TAS * Math.cos(dc) - GS);
    if (wd < 0) {
      wd = wd + 2 * Math.PI;
    }
    if (wd > 2 * Math.PI) {
      wd = wd - 2 * Math.PI;
    }
    return wd;
  }


  // Temp Differenz zur Standardtemperatur
  calcDeltaT(t: UnitClass, e: UnitClass): UnitClass {
    const ht = Math.round(this.gVwU(e, 'ft') / 1000);
    const dt = this.gVwU(t, 'C') - 15 - ht;
    return new UnitClass('C', dt, -273, 100, 'T');
  }

  /** Berechnung aus aktuellem Luftdruck pstat in mbar */
  pressureAltitude(qnh, elevation) {
    const pstat = qnh * Math.pow((288 - 0.0065 * elevation) / 288, 5.2561);
    const h = (1 - Math.pow(pstat / 1013.25, 0.190284)) * 145366.42;
    return h;
  }

  /**
   *  Dichtehöhe in FT.
   * @param T T in C
   * @param qnh in mbar
   * @param elevation in ft
   */
  densityAltitudePrecise(T, qnh, elevation) {
    const t1 = 288 / (T + 273.15);
    const p1 = qnh / 1013.25;
    const r1 = (t1 * p1 / Math.pow(2, elevation / 18000));
    const r2 = 8464.42 / r1;
    const res = 25329.72 + r2 - 46726.31 * r1 + 15329.9 * r1 * r1 - 2397.73 * r1 * r1 * r1;
    return res;
  }

  /** QNH in hPa ; elevation in m */
  QFEfromQNH(qnh, elevation) {
    return Math.pow(Math.abs(0.00008417168 * elevation - Math.pow(qnh, 0.1902612)), 1 / 0.1902612);
  }

  /** QNH in hPa ; elevation in m */
  QNHfromQFE(qfe, elevation) {
    return Math.pow(Math.abs(0.00008417168 * elevation + Math.pow(qfe, 0.1902612)), 1 / 0.1902612);
  }

  /** Berechnet den im Höhenmesser angezeigte Druck in mbar abh. vom Druck (mbar) und der Stationshöhe (m) */
  altimeterSetting(p, elevation) {
    const e = (p - 0.3) * Math.pow(1 + ((Math.pow(1013.25, 0.190284) * 0.0065 / 288) * (elevation / Math.pow(p - 0.3, 0.190284))), 1 / 0.190284);
    return e;
  }

  /** Berechnet Druck am Standort  in mbar abh. vom QNH (mbar) und der Stationshöhe (m) */
  stationPressure(p, elevation) {
    const e = p * Math.pow((288 - 0.0065 * elevation) / 288, 5.2561);
    return e;
  }


  /**
   * Gibt die Gleizahl zurück.
   * @param height        // in ft
   * @param distance      // in km
   */
  glideRatio(height: UnitClass, distance: UnitClass) {
    return height.value !== 0 ? this.gVwU(distance, 'ft') / this.gVwU(height, 'ft') : 0;
  }

  /**
   * Berechnet die Ankunftshöhe  in ft
   * @param altitude     in ft
   * @param distance     in m
   * @param gliderratio  ohne Einheit
   */
  ankunftsHoehe(altitude: UnitClass, distance: UnitClass, gliderratio: number) {
    return this.gVwU(altitude, 'ft') - this.gVwU(distance, 'ft') / gliderratio;
  }


  /**
   * Berechnet aus dem String die Minuten
   */
  strToMinutes(str: string, mode) {


    if (mode === 'hh.mmm') {
      if (str.length === 0 || str === '.') {
        return 0;
      }
      const m = parseFloat(str) * 60;
      return m;
    }

    if (mode === 'mm') {
      if (str.length === 0 || str === '.') {
        return 0;
      }
      const m = parseFloat(str);
      return m;
    }

    const res = str.split(':');
    let min = 0;
    switch (res.length) {
      case 3:
        min = 24 * 60 * parseInt('0' + res[0].trim(), 10);
        min += 60 * parseInt('0' + res[1].trim(), 10);
        min = min + parseInt('0' + res[2].trim(), 10);
        break;
      case 2:
        min = 60 * parseInt('0' + res[0].trim(), 10);
        min = min + parseInt('0' + res[1].trim(), 10);
        break;
      default:
        min = min + parseInt('0' + res[0].trim(), 10);
    }
    return min;
  }

  calcWindRad(rwK, wd) {
    const delta = this.normAngleRad(rwK - 2 * Math.PI - wd);
    const crosswind = Math.sin(delta);
    const headwind = Math.cos(delta);
    return {delta, crosswind, headwind};
  }

  calcWindDeg(rwK, wd) {
    return this.calcWindRad(
      this.deg2rad(rwK),
      this.deg2rad(wd)
    );
  }

  polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  arc(x, y, radius, startAngle, endAngle, longway = '') {

    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);
    let largeArcFlag = longway;
    if (longway === '') {
      largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';
    }

    const d = [
      'M', x, y,
      'L', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y, 'Z'
    ].join(' ');

    return d;
  }


  zfill(num, len) {
    let s = '000000000';
    const n = num.toString();
    s = s + n;
    if (len < n.length) {
      return s.slice(n.length * -1);
    } else {
      return s.slice(len * -1);
    }
  }


  format_hhmmss(min) {
    let restmin = min;
    const hh = Math.floor(restmin / 60 / 60);
    restmin = restmin - hh * 60 * 60;
    const mm = Math.floor(restmin / 60);
    const ss = restmin - mm * 60;
    return this.zfill(hh, 2).toString() + ':' + this.zfill(mm, 2).toString() + ':' + this.zfill(ss, 2).toString();
  }

  formattime(min, mode) {
    let restmin = min;
    const dd = Math.floor(restmin / 24 / 60);
    restmin = restmin - dd * 24 * 60;
    let hh = Math.floor(restmin / 60);
    restmin = restmin - hh * 60;
    const mm = restmin;

    switch (mode) {
      case 'dd:hh:mm':
        return this.zfill(dd, 2).toString() + ':' + this.zfill(hh, 2).toString() + ':' + this.zfill(mm, 2).toString();
      case 'hh.mmm':
        return (min / 60).toString();
      case 'mm':
        return (min).toString();
      default:
        hh = hh + dd * 24;
        return this.zfill(hh, 2).toString() + ':' + this.zfill(mm, 2).toString();
    }
  }

  isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }


}
